<template>
  <section id="edit-vendor-shop">
    <b-row class="mb-2">
      <b-col
        md="8"
        class="pb-3 pb-md-0"
      >
        <h1>{{ $t('editVend') }}</h1>
        <h5 class="text-primary">
          {{ $t('confVend') }}
        </h5>
      </b-col>
      <b-col class="d-flex justify-content-end align-items-center">
        <b-button
          variant="danger"
          @click="goBack()"
        >
          {{ $t("dataGeneric.goBack") }}
        </b-button>
      </b-col>
    </b-row>
    <b-row class="d-flex justify-content-end mb-5 ">
      <b-col class="ml-2">
        <h2>{{ vendorName }}</h2>
      </b-col>
      <b-col class="d-flex justify-content-end align-items-center" />
    </b-row>
    <b-tabs
      v-if="mostrarDatos"
      pills
    >
      <!-- DATA VENDOR -->
      <b-tab active>
        <template #title>
          <feather-icon icon="FileTextIcon" />
          <span>{{ $t("contents.categoryData") }}</span>
        </template>
        <b-row class="match-height mt-2">
          <b-col>
            <b-overlay
              variant="transparent"
              :show="showData"
            >
              <b-card>
                <b-card-header>
                  <b-card-title>{{ $t("contents.categoryData") }}</b-card-title>
                </b-card-header>
                <b-card-body>
                  <b-row>
                    <b-col
                      lg="11"
                      class="ml-2 mb-2"
                    >
                      <b-form @submit.prevent="updateVendorData">
                        <!-- form input -->
                        <b-form-row class="align-items-center">
                          <b-col>
                            <b-form-group
                              class="mb-2 mr-1"
                              :label="$t('nameShop')+' *'"
                            >
                              <b-form-input
                                v-model="vendorName"
                                :placeholder="$t('nameShop')"
                                maxlength="150"
                                class="mr-1"
                                minlength="3"
                                required
                              />
                            </b-form-group>
                          </b-col>
                          <b-col>
                            <b-form-group
                              class="mb-2 mr-1"
                              :label="$t('firstName')+' *'"
                            >
                              <b-form-input
                                v-model="name"
                                :placeholder="$t('firstName')"
                                maxlength="150"
                                class="mr-1"
                                minlength="3"
                                required
                              />
                            </b-form-group>
                          </b-col>
                          <b-col>
                            <b-form-group
                              class="mb-2 mr-1"
                              :label="$t('administrators.lname')"
                            >
                              <b-form-input
                                v-model="lastName"
                                :placeholder="$t('administrators.lname')"
                                maxlength="150"
                                class="mr-1"
                              />
                            </b-form-group>
                          </b-col>
                        </b-form-row>

                        <b-form-row class="align-items-center">
                          <b-col class="mr-1">
                            <b-form-group :label="$t('finalUsers.Phone')">
                              <b-form-input
                                id="phone"
                                v-model="phone"
                                maxlength="9"
                                value="phone"
                                type="text"
                                :placeholder="$t('finalUsers.Phone')"
                                @input="validationPhone(phone)"
                              />
                              <small class="text-danger">{{ msgPhone }}</small>
                            </b-form-group>
                          </b-col>
                          <b-col class="mr-1">
                            <b-form-group :label="'E-mail'">
                              <b-form-input
                                id="email"
                                v-model="email"
                                maxlength="100"
                                value="email"
                                type="email"
                                placeholder="E-mail"
                              />
                            </b-form-group>
                          </b-col>

                          <b-col class="mr-1">
                            <b-form-group :label="'NIF/ CIF/ NIE'">
                              <b-form-input
                                id="nif"
                                v-model="nif"
                                type="text"
                                maxlength="9"
                                value="nif"
                                placeholder="nif/ cif/ nie"
                                @input="validationDni(nif)"
                              />
                              <small class="text-danger">{{ msgDni }}</small>
                            </b-form-group>
                          </b-col>
                        </b-form-row>
                        <b-form-row class="align-items-center">
                          <b-col
                            md="5"
                            class="mr-1"
                          >
                            <b-form-group :label="$t('externalRef')">
                              <b-form-input
                                id="reference"
                                v-model="reference"
                                maxlength="150"
                                value="reference"
                                :placeholder="$t('externalRefVend')"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col
                            md="5"
                            class="mr-1"
                          >
                            <b-form-group :label="'Packlink api key '">
                              <b-form-input
                                id="packlinkApiKey"
                                v-model="packlinkApiKey"
                                maxlength="150"
                                value="packlinkApiKey"
                                placeholder="Packlink api key  "
                              />
                            </b-form-group>
                          </b-col>
                          <b-col>
                            <b-form-checkbox
                              v-model="isActive"
                              switch
                            >
                              {{ $t('dataGeneric.activeQ') }}
                            </b-form-checkbox>
                          </b-col>
                        </b-form-row>
                        <b-button
                          type="submit"
                          variant="success"
                          :disabled="!nifValidation & !boolPhone ? false : ''"
                        >
                          {{ $t("dataGeneric.save") }}
                        </b-button>
                      </b-form>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
            </b-overlay>
          </b-col>
        </b-row>
      </b-tab>
      <!-- ADDRESS VENDOR -->
      <b-tab>
        <template #title>
          <feather-icon icon="MapPinIcon" />
          <span>{{ $t('locationVend') }}</span>
        </template>
        <b-row class="match-height mt-2">
          <b-col>
            <b-overlay
              variant="transparent"
              :show="showData"
            >
              <b-card>
                <b-card-header>
                  <b-card-title>{{ $t('locationVend') }}</b-card-title>
                </b-card-header>
                <b-card-body>
                  <b-row>
                    <b-col
                      lg="11"
                      class="ml-2 mb-2"
                    >
                      <b-form @submit.prevent="updateAddressData">
                        <!-- form input -->
                        <b-form-row class="align-items-center">
                          <b-col>
                            <b-form-group
                              class="mb-2 mr-1"
                              :label="$t('finalUsers.Address')"
                            >
                              <b-form-input
                                v-model="address"
                                :placeholder="$t('finalUsers.Address')"
                                maxlength="150"
                                class="mr-1"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col>
                            <b-form-group
                              class="mb-2 mr-1"
                              :label="$t('localidad')"
                            >
                              <b-form-input
                                v-model="city"
                                :placeholder="$t('localidad')"
                                maxlength="150"
                                class="mr-1"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col>
                            <b-form-group
                              class="mb-2 mr-1"
                              :label="$t('finalUsers.Province')"
                            >
                              <b-form-input
                                v-model="province"
                                :placeholder="$t('finalUsers.Province')"
                                maxlength="150"
                                class="mr-1"
                              />
                            </b-form-group>
                          </b-col>
                        </b-form-row>

                        <b-form-row class="align-items-center">
                          <b-col class="mr-1">
                            <b-form-group :label="$t('finalUsers.Code')">
                              <b-form-input
                                id="postalCode"
                                v-model="postalCode"
                                maxlength="5"
                                value="postalCode"
                                type="text"
                                :placeholder="$t('finalUsers.Code')"
                                @input="validationPostal(postalCode)"
                              />
                              <small class="text-danger">{{ msgPostal }}</small>
                            </b-form-group>
                          </b-col>
                        </b-form-row>
                        <b-form-row class="align-items-center">
                          <b-col class="mr-1">
                            <b-form-group :label="$t('finalUsers.Country')">
                              <b-form-select
                                v-model="country"
                                class="mb-3"
                              >
                                <b-form-select-option
                                  :value="null"
                                  disabled
                                >
                                  {{ $t('selectCountry') }}
                                </b-form-select-option>
                                <b-form-select-option
                                  v-for="coun in allCountry"
                                  :key="coun.node.id"
                                  :value="coun.node.id"
                                >
                                  {{ coun.node.country }}
                                </b-form-select-option>
                              </b-form-select>
                            </b-form-group>
                          </b-col>
                        </b-form-row>
                        <b-button
                          type="submit"
                          variant="success"
                          :disabled="boolPostal"
                        >
                          {{ $t("dataGeneric.save") }}
                        </b-button>
                      </b-form>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
            </b-overlay>
          </b-col>
        </b-row>
      </b-tab>
      <!-- ARCHIVOS DEL VENDOR -->
      <b-tab>
        <template #title>
          <feather-icon icon="FilmIcon" />
          <span>{{ $t("contents.categoryFiles") }}</span>
        </template>
        <b-row class="match-height mt-2">
          <b-col>
            <b-overlay
              variant="transparent"
              :show="showFiles"
            >
              <b-card>
                <b-card-header>
                  <b-card-title>
                    {{
                      $t("contents.categoryFiles")
                    }}
                  </b-card-title>
                </b-card-header>
                <b-row>
                  <b-col
                    lg="11"
                    class="ml-2 mb-2"
                  >
                    <b-form @submit.prevent="updateVendorFiles">
                      <!-- form input -->
                      <b-form-group
                        class="mb-2 mr-1"
                        :label="$t('resources.originMedia')"
                      >
                        <b-form-select
                          v-model="mediaOriginSelected"
                          class="mr-1"
                          :options="mediaOriginOptions"
                          @change="onChange()"
                        />
                      </b-form-group>
                      <template v-if="mediaOriginSelected == 'DIR'">
                        <b-row class="tamanio">
                          <b-col>
                            <label class="d-flex justify-content-between">
                              <span> {{ $t("contents.imgExternal") }}</span>
                            </label>
                            <b-form-group
                              class="w-50
                                                        mb-2
                                                        mr-1
                                                        embed-responsive embed-responsive-1by1
                                                    "
                            >
                              <div
                                class="base-image-input embed-responsive-item"
                                :style="{
                                  background: `url('${vendorImage}') center/cover no-repeat,white center/cover no-repeat`
                                }"
                                @click="chooseImage('fileInput')"
                              >
                                <span
                                  v-if="!vendorImage"
                                  class="placeholder"
                                >
                                  <feather-icon
                                    icon="ImageIcon"
                                    size="80"
                                  />
                                </span>
                              </div>
                            </b-form-group>
                          </b-col>
                        </b-row>

                        <b-button
                          type="submit"
                          variant="success"
                        >
                          {{ $t("dataGeneric.save") }}
                        </b-button>
                      </template>
                      <template v-else-if="mediaOriginSelected == 'BUK'">
                        <b-row class="tamanio">
                          <b-col>
                            <label class="d-flex justify-content-between">
                              <span> {{ $t("contents.imgInternal") }}</span>

                            </label>
                            <b-form-group
                              class="w-50
                                                                mb-2
                                                                mr-1
                                                                embed-responsive embed-responsive-1by1
                                                            "
                            >
                              <div
                                class="base-image-input embed-responsive-item"
                                :style="{
                                  background: `${vendorImageSrc == null
                                    ? `url(${vendorImageSelectedPlaceholder ==
                                      null
                                      ? vendorImage
                                      : vendorImageSelectedPlaceholder
                                    }) center/cover no-repeat,white center/cover no-repeat`
                                    : ''
                                  }`
                                }"
                                @click="chooseImage('fileInput')"
                              >
                                <span
                                  v-if="!vendorImageSelected"
                                  class="placeholder"
                                >
                                  <feather-icon
                                    icon="ImageIcon"
                                    size="80"
                                  />
                                </span>
                                <b-img
                                  v-else
                                  :src="vendorImageSrc"
                                  class="mb-3 embed-responsive-item"
                                  fluid
                                  block
                                  rounded
                                />
                                <b-form-file
                                  id="fileInput"
                                  v-model="vendorImage"
                                  accept="image/ *"
                                  class="mr-1"
                                  style="display: none"
                                />
                              </div>
                            </b-form-group>
                          </b-col>
                        </b-row>

                        <b-button
                          type="submit"
                          variant="success"
                        >
                          {{ $t("dataGeneric.save") }}
                        </b-button>
                      </template>
                    </b-form>
                  </b-col>
                </b-row>
              </b-card>
            </b-overlay>
          </b-col>
        </b-row>
      </b-tab>
      <!-- PRICE DATA VENDOR -->
      <b-tab>
        <template #title>
          <feather-icon icon="DollarSignIcon" />
          <span>{{ $t('Datos económicos') }}</span>
        </template>
        <b-row class="match-height mt-2">
          <b-col>
            <b-overlay
              variant="transparent"
              :show="showBank"
            >
              <b-card>
                <b-card-header>
                  <b-card-title>{{ $t('Datos bancarios') }}</b-card-title>
                </b-card-header>
                <b-card-body>
                  <b-row>
                    <b-col
                      lg="11"
                      class="ml-2 mb-2"
                    >
                      <b-form @submit.prevent="updateBankData">
                        <b-form-row class="align-items-center">
                          <b-col>
                            <b-form-group
                              class="mb-2 mr-1"
                              :label="$t('Nombre del banco')+' *'"
                            >
                              <b-form-input
                                v-model="bankName"
                                required
                                :placeholder="$t('Nombre del banco')"
                                maxlength="150"
                                class="mr-1"
                              />
                            </b-form-group>
                          </b-col>
                        </b-form-row>
                        <b-form-row>
                          <b-col>
                            <b-form-group
                              class="mb-2 mr-1"
                              :label="$t('IBAN')"
                            >
                              <b-form-input
                                v-model="iban"
                                required
                                :placeholder="$t('IBAN')"
                                maxlength="150"
                                class="mr-1"
                              />
                            </b-form-group>
                          </b-col>
                        </b-form-row>
                        <b-form-row>
                          <b-col>
                            <b-form-group
                              class="mb-2 mr-1"
                              :label="$t('userExternal')"
                            >
                              <b-form-input
                                v-model="stripeUser"
                                :placeholder="$t('userExternal')"
                                maxlength="150"
                                class="mr-1"
                              />
                            </b-form-group>
                          </b-col>
                        </b-form-row>
                        <b-button
                          type="submit"
                          variant="success"
                        >
                          {{ $t("dataGeneric.save") }}
                        </b-button>
                      </b-form>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
            </b-overlay>
            <b-overlay
              variant="transparent"
              :show="showVat"
            >
              <b-card class="mt-1">
                <b-card-header>
                  <b-card-title>{{ $t('impuestos') }}</b-card-title>
                </b-card-header>
                <b-card-body>
                  <b-row>
                    <b-col
                      lg="11"
                      class="ml-2 mb-2"
                    >
                      <b-form @submit.prevent="updateVatData">
                        <b-form-row class="align-items-center">
                          <b-col>
                            <b-form-group
                              class="mb-2 mr-1"
                              :label="$t('valor')+' *'"
                            >
                              <b-form-input
                                v-model="commissionValue"
                                required
                                :placeholder="$t('valor')"
                                type="number"
                                class="mr-1"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col class="d-none">
                            <b-form-group
                              class="mb-2 mr-1"
                              :label="$t('Tipo de comisión')"
                            >
                              <b-form-select
                                v-model="commissionType"
                                :options="[
                                  { value: 'NON', text: $t('Ninguno') },
                                  { value: 'FLA', text: $t('fija') },
                                  { value: 'PER', text: $t('Porcentaje') },
                                ]"
                                class="mr-1"
                              />
                            </b-form-group>
                          </b-col>
                        </b-form-row>
                        <b-form-row class="align-items-center">
                          <b-col>
                            <b-form-group
                              class="mb-2 mr-1"
                              :label="$t('Impuestos')+' *'"
                            >
                              <b-form-input
                                v-model="vatNumber"
                                required
                                :placeholder="$t('Impuestos')"
                                type="number"
                                class="mr-1"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col>
                            <b-form-checkbox
                              v-model="isFeatured"
                              switch
                            >
                              {{ $t('Es presentado') }}
                            </b-form-checkbox>
                          </b-col>
                        </b-form-row>

                        <b-button
                          type="submit"
                          variant="success"
                        >
                          {{ $t("dataGeneric.save") }}
                        </b-button>
                      </b-form>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
            </b-overlay>
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>
  </section>
</template>

<script>

import {
  showToast, messageError, base64Encode, phoneNumber, identityDocument, postal,
} from '@/store/functions'

import {
  BFormFile,
  BForm,
  BFormCheckbox,
  BFormInput,
  BFormSelectOption,
  BFormGroup,
  BFormRow,
  BRow,
  BCol,
  BCard,
  BButton,
  BFormSelect,
  BTabs, BTab,
  BCardHeader,
  BImg,
  BCardBody,
  VBTooltip,
  BCardTitle,
  BOverlay,
} from 'bootstrap-vue'
import axios from '@axios'
import Ripple from 'vue-ripple-directive'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BRow,
    BTabs,
    BTab,
    BCol,
    BCard,
    BButton,
    BFormSelect,
    BForm,
    BFormCheckbox,
    BFormInput,
    BFormGroup,
    BFormFile,
    BFormSelectOption,
    BFormRow,
    BCardHeader,
    BImg,
    BCardBody,
    BCardTitle,
    BOverlay,
  },

  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      vendorName: null,
      lastName: null,
      phone: null,
      email: null,
      nif: null,
      bankName: null,
      iban: null,
      stripeUser: null,
      address: null,
      province: null,
      city: null,
      packlinkApiKey: '',
      postalCode: null,
      country: null,
      allCountry: [],
      isActive: false,
      commissionValue: 0,
      commissionType: 'NON',
      isFeatured: false,
      vatNumber: 21,
      userData: getUserData(),
      id: this.$route.params.id,
      idSelectCard: null,
      vendorImageSrc: null,
      vendorImage: null,
      name: '',
      imageClicked: null,
      reference: '',
      mediaOriginSelected: 'BUK',
      mediaOriginOptions: [
        { value: 'BUK', text: this.$t('dataGeneric.internal') },
        { value: 'DIR', text: this.$t('dataGeneric.external') },
      ],
      vendorImageSelected: null,
      vendorImageSelectedPlaceholder: '',
      mostrarDatos: false,
      showData: false,
      showFiles: false,
      showDesing: false,
      showDesingTit: false,
      showBank: false,
      showVat: false,
      msgDni: '',
      msgPhone: '',
      msgPostal: '',
      nifValidation: false,
      boolPhone: false,
      boolPostal: false,

    }
  },
  watch: {
    vendorImage(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then(value => {
              this.vendorImageSelected = true
              this.vendorImageSrc = value
            })
            .catch(() => {
              this.vendorImageSrc = null
            })
        } else {
          this.vendorImageSrc = null
        }
      }
    },
  },
  mounted() {
    this.countryList()
    this.fetch()
  },
  methods: {
    validationDni(dni) {
      identityDocument(dni).then(result => {
        this.nifValidation = result
        this.msgDni = ''
      }).catch(err => {
        this.nifValidation = true
        this.msgDni = err
      })
    },
    validationPhone(phone) {
      phoneNumber(phone).then(result => {
        this.boolPhone = result
        this.msgPhone = ''
      }).catch(err => {
        this.boolPhone = true
        this.msgPhone = err
      })
    },
    validationPostal(post) {
      postal(post).then(result => {
        this.boolPostal = result
        this.msgPostal = ''
      }).catch(err => {
        this.boolPostal = true
        this.msgPostal = err
      })
    },
    countryList() {
      const query = `
            {
                allCountry {
                edges {
                    node {
                    id
                    country
                    }
                }
            }
        }  
        `
      axios
        .post('', { query })
        .then(result => {
          messageError(result, this)

          this.allCountry = result.data.data.allCountry.edges
        })
        .catch(err => { console.log(err) })
    },
    showModalCard(type) {
      this.$refs.modalCard.show()
      switch (type) {
        case 'imageCatTV':
          this.idSelectCard = this.cardSelected

          break
        case 'imageContTV':
          this.idSelectCard = this.cardMobileSelected

          break

        default:
          break
      }
      this.typeCard = type
    },
    fetch() {
      const query = `
            query{        
                allVendors(id:"${this.id}") {                
                    edges {
                        node {
                            id
                            name
                            lastName
                            vendorName
                            phone
                            email
                            nif
                            mediaLocation
                            vendorImage
                            imageVendorUrl
                            bankName
                            iban
                            isActive
                            reference
                            packlinkApiKey
                            stripeUser
                            commissionValue
                            commissionType
                            isFeatured 
                            vatNumber
                            address
                            province
                            city
                            postalCode
                            
                            country {
                            id
                            country
                            
                            }
                        
                    }
                }
            }
        } `
      axios.post('', { query }).then(res => {
        messageError(res, this)

        const datos = res.data.data.allVendors.edges[0].node
        this.name = datos.name
        this.vendorName = datos.vendorName
        this.lastName = datos.lastName
        this.phone = datos.phone
        this.email = datos.email
        this.nif = datos.nif
        this.bankName = datos.bankName
        this.iban = datos.iban
        this.stripeUser = datos.stripeUser
        this.isActive = datos.isActive
        this.commissionValue = datos.commissionValue
        this.commissionType = datos.commissionType
        this.isFeatured = datos.isFeatured
        this.reference = datos.reference
        this.packlinkApiKey = datos.packlinkApiKey

        this.vatNumber = datos.vatNumber

        this.address = datos.address
        this.province = datos.province
        this.city = datos.city
        this.postalCode = datos.postalCode
        this.country = datos.country != null ? datos.country.id : null

        this.vendorImageSelectedPlaceholder = datos.imageVendorUrl
        try {
          this.vendorImageSelected = this.vendorImageSelectedPlaceholder.trim() !== ''
        // eslint-disable-next-line no-empty
        } catch (error) {

        }

        this.mediaOriginSelected = datos.mediaLocation

        this.mostrarDatos = true

        switch (this.mediaOriginSelected) {
          case 'DIR':
            this.vendorImage = datos.imageVendorUrl

            break
          default:
            break
        }
      }).catch(err => {
        console.log(err)
      })
    },
    updateVendorData(event) {
      this.showData = true
      event.preventDefault()

      axios
        .post('', {
          query: `
            mutation{
                updateVendors(id: "${this.id}",input:{
                    vendorName: "${this.vendorName}",
                    name: "${this.name}",
                    lastName: "${this.lastName}",
                    reference: "${this.reference}",
                    packlinkApiKey: "${this.packlinkApiKey}",
                    
                    nif: "${this.nif}",
                    email: "${this.email}",
                    isActive: ${this.isActive},
                    phone: "${this.phone}",
                    
                }
                
                ){
                    vendor{
                        id
                        name
                       
                        isActive                
                }
                }
            }
            `,
        })
        .then(res => {
          messageError(res, this)

          showToast(
            this.$t('success'),
            1, this,
          )
          this.showData = false
          // this.getCategoryInfo();
        })
        .catch(error => {
          console.log(error)
          showToast(this.$t('error'), 2, this)
          this.showData = false
        })
    },
    updateAddressData(event) {
      this.showData = true
      event.preventDefault()

      axios
        .post('', {

          query: `
            mutation{
                updateVendors(id: "${this.id}",input:{
                    address: "${this.address}",
                    city: "${this.city}",
                    province: "${this.province}",
                    postalCode: "${this.postalCode}",
                    country: "${this.country}",
                }
                
                ){
                    vendor{
                        id
                        name
                       
                        isActive                
                }
                }
            }
            `,
        })
        .then(res => {
          messageError(res, this)

          showToast(
            this.$t('success'),
            1, this,
          )
          this.showData = false
          // this.getCategoryInfo();
        })
        .catch(error => {
          console.log(error)
          showToast(this.$t('error'), 2, this)
          this.showData = false
        })
    },
    updateBankData(event) {
      this.showBank = true
      event.preventDefault()

      axios
        .post('', {

          query: `
              mutation{
                  updateVendors(id: "${this.id}",input:{
                      bankName: "${this.bankName}",
                      iban: "${this.iban}",
                      stripeUser: "${this.stripeUser}",                          
                      
                  }
                  
                  ){
                      vendor{
                          id
                          name
                         
                          isActive                
                  }
                  }
              }
              `,
        })
        .then(res => {
          messageError(res, this)

          showToast(
            this.$t('success'),
            1, this,
          )
          this.showBank = false
          // this.getCategoryInfo();
        })
        .catch(error => {
          console.log(error)
          showToast(this.$t('error'), 2, this)
          this.showBank = false
        })
    },
    updateVatData(event) {
      this.showVat = true
      event.preventDefault()

      axios
        .post('', {

          query: `
              mutation{
                  updateVendors(id: "${this.id}",input:{
                      commissionType: ${this.commissionType},
                      commissionValue: ${this.commissionValue},                          
                      vatNumber: ${this.vatNumber},                          
                      isFeatured: ${this.isFeatured}                    
                      
                  }
                  
                  ){
                      vendor{
                          id
                          name
                         
                          isActive                
                  }
                  }
              }
              `,
        })
        .then(res => {
          messageError(res, this)

          showToast(
            this.$t('success'),
            1, this,
          )
          this.showVat = false
        })
        .catch(error => {
          console.log(error)
          showToast(this.$t('error'), 2, this)
          this.showVat = false
        })
    },
    goBack() {
      window.history.back()
    },
    chooseImage(imageClicked) {
      this.imageClicked = imageClicked
      if (this.mediaOriginSelected === 'BUK') {
        document.getElementById(imageClicked).click()
      } else if (this.mediaOriginSelected === 'DIR') {
        this.$swal({
          title: this.$t('code.indicar'),
          input: 'url',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
          inputAttributes: {
            autocapitalize: 'off',
          },
          showCancelButton: true,
          confirmButtonText: this.$t('dataGeneric.save'),
          cancelButtonText: this.$t('dataGeneric.cancel'),
          showLoaderOnConfirm: true,
        }).then(result => {
          if (result.value) {
            this.processImageData(result.value)
          }
        })
      }
    },
    onChange() {
      if (this.mediaOriginSelected === 'BUK') {
        this.imageClicked = ''
        this.vendorImage = null
        this.mobileImageCategory = null
        this.backgroundCategory = null

        this.vendorImageSelected = false
        this.mobileImageCategorySelected = false
        this.backgroundCategorySelected = false
      } else {
        this.imageClicked = ''
        this.vendorImage = null
        this.mobileImageCategory = null
        this.backgroundCategory = null

        this.vendorImageSelected = false
        this.mobileImageCategorySelected = false
        this.backgroundCategorySelected = false
      }
    },
    processImageData(data) {
      switch (this.imageClicked) {
        case 'fileInput':
          this.vendorImage = data
          this.imageClicked = ''
          break
        case 'fileInputMob':
          this.mobileImageCategory = data
          this.imageClicked = ''
          break

        case 'fileInputBck':
          this.backgroundCategory = data
          this.imageClicked = ''
          break

        default:
          break
      }
    },
    updateVendorFiles(event) {
      this.showFiles = true

      event.preventDefault()
      if (this.mediaOriginSelected === 'DIR') {
        axios
          .post('', {
            variables: {
              imageVendorUrl: this.vendorImage,

            },
            query: `
                            mutation($imageVendorUrl: String) {
                                updateVendors(id: "${this.id}", input:{
                                imageVendorUrl: $imageVendorUrl,                                    
                                mediaLocation: DIR
                                }
                                ){
                                vendor{
                                id
                                name
                                
                                }
                            }
                            }
                        `,
          })
          .then(res => {
            messageError(res, this)

            showToast(
              this.$t('success'),
              1, this,
            )
            this.showFiles = false
          })
          .catch(() => {
            this.showFiles = false

            showToast(this.$t('error'), 2, this)
          })
        this.showFiles = false
      } else {
        this.processBucketFiles()
      }
    },
    processBucketFiles() {
      const config = {
        timeout: 1000 * 60 * 30,
      }
      const data = new FormData()
      const query = `
            mutation{
                updateVendors(id:"${this.id}",input:{
                    mediaLocation: BUK
                }
                ){
                    vendor{
                        id
                        name                
                    }
                    }
                }
            `
      data.append('query', query)

      if (this.vendorImageSelected) data.append('file', this.vendorImage)

      axios.post('', data, config).then(createResponse => {
        messageError(createResponse, this)

        this.showFiles = false

        showToast(this.$t('code.updateData'), 1, this)
      }).catch(err => {
        console.log(err)
        this.showFiles = false

        showToast(this.$t('code.updateDataError'), 2, this)
      })
    },

    deleteImage(type) {
      this.$swal({
        title: this.$t('removData'),
        text: this.$t('sure'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('code.confirm'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        // cancelButtonText: "Indicar url",
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.isConfirmed) {
            this.deleteFile.push(type)
            switch (type) {
              case 'fileInput':
                this.vendorImage = null
                this.vendorImageSelected = false

                break
              case 'fileInputMob':
                this.mobileImageCategory = null
                this.mobileImageCategorySelected = false

                break
              case 'fileInputBck':
                this.backgroundCategory = null
                this.backgroundCategorySelected = false

                break
              default:
                break
            }
          }
        })
        .catch(() => { })
    },
    modalTags() {
      if (this.selected.length > this.idTags.length) {
        let id = ''
        axios
          .post('', {
            query: `
      {
        allTags(name:"${this.selected[this.selected.length - 1]}") {
          edges {
            node {
              id
              name
              createdAt

            }
          }
        }
      }
    `,
          })
          .then(result => {
            messageError(result, this)

            let crear = true
            result.data.data.allTags.edges.forEach(element => {
              if (
                element.node.name.toLowerCase()
                === this.selected[this.selected.length - 1].toLowerCase()
              ) {
                id = element.node.id
                crear = false
              }
            })

            if (crear) {
              /* AQUÍ CREA EL TAG */

              axios
                .post('', {
                  query: `
          mutation{
            createTag(input:{name:"${this.selected[this.selected.length - 1]
}"}){
                tag{
                    id
                    name
                }
            }
        }
        `,
                })
                .then(res => {
                  messageError(res, this)

                  this.idTags.push(res.data.data.createTag.tag.id)
                })
                .catch(() => { })
            } else {
              this.idTags.push(id)
              // console.log(this.idTags);
            }
          })
          .catch(() => { })
      } else {
        // console.log(this.selected);
        this.originalTags.forEach((a, index) => {
          let quitar = true
          this.selected.forEach(element => {
            if (element.toLowerCase() === a.toLowerCase()) {
              quitar = false
            }
          })
          if (quitar) {
            this.idTags.splice(index, 1)
            this.originalTags.splice(index, 1)
          }
        })
      }
    },
    getTags() {
      axios
        .post('', {
          query: `
      {
        allTags {
          edges {
            node {
              id
              name
              createdAt

            }
          }
        }
      }
    `,
        })
        .then(result => {
          messageError(result, this)

          result.data.data.allTags.edges.forEach(element => {
            this.option.push({
              title: element.node.name,
              value: element.node.id,
            })
          })
        })
        .catch(() => { })
    },
    newTag(data) {
      this.option.push({
        title: data.name,
        value: data.id,
      })
      this.selected.push({
        title: data.name,
        value: data.id,
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "vue-context/src/sass/vue-context";

#edit-vendor-shop .scroll-list {
  overflow: hidden;
  overflow-y: scroll;
  height: 200px;
}

#edit-vendor-shop #add-new-category-sidebar {
  #edit-vendor-shop .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

#edit-vendor-shop .base-image-input {
  display: block;
  /* width: 200px;
  height: 200px; */
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}

#edit-vendor-shop .placeholder {
  background: #f0f0f0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 18px;
  font-family: Helvetica;
}

#edit-vendor-shop .placeholder:hover {
  background: #e0e0e0;
}

#edit-vendor-shop .file-input {
  display: none;
}

#edit-vendor-shop .final-info {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  font-weight: bold;
  color: aquamarine;
  font-size: smaller;
}

#edit-vendor-shop .tamanio {
  width: 50%;
}
</style>
